import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import "./i18n";
import i18n from "./i18n";
import themes from "./themes";
import {I18nextProvider} from "react-i18next";
import {ThemeSwitcherProvider} from "react-css-theme-switcher";
import {EcgView} from "./Components/EcgView/EcgView";
import NotFound from "./Components/NotFound/NotFound";
import {Welcome} from "./Components/Welcome/Welcome";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children:[{
            path: "/",
            element: <Welcome/>
        }, {
            path: "view/:userId",
            element: <EcgView/>
        }],
        errorElement: <NotFound/>
    },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <ThemeSwitcherProvider defaultTheme="light" themeMap={themes} insertionPoint={"inject-styles-here"}>
                <RouterProvider router={router}/>
            </ThemeSwitcherProvider>
        </I18nextProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
