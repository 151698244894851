import * as React from "react";

function SvgZoomOutHorizontalIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.106 6.05H3.322l1.203 1.203a.66.66 0 010 .93.66.66 0 01-.93 0L1.27 5.859a.66.66 0 010-.93l2.325-2.325a.66.66 0 01.93 0 .66.66 0 010 .93L3.322 4.736h6.784a.656.656 0 110 1.314z"
        fill="currentColor"
      />
      <path
        d="M9.751 4.928a.657.657 0 01.465-.192H17l-1.204-1.203a.66.66 0 010-.93.66.66 0 01.93 0l2.326 2.325a.66.66 0 010 .93l-2.325 2.325a.66.66 0 01-.93 0 .66.66 0 010-.93L17 6.05h-6.784a.66.66 0 01-.465-1.122z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgZoomOutHorizontalIcon;
