import './Welcome.css';
import {Alert, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useRef, useState} from "react";
import IconPasteSvg from "../../Assets/Svg/IconPasteSvg";
import {Button} from "../Button/Button";
import {useNavigate} from "react-router-dom";

export function Welcome(){
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [showToast, setShowToast] = useState(false);
    const [timerId, setTimerId] = useState(null as NodeJS.Timeout | null);
    const linkRef = useRef<HTMLInputElement>(null);
    const pasteButtonClickHandler = () => {
        navigator.clipboard.readText().then(text => {
            const inputElement = linkRef.current;
            if (inputElement) {
                inputElement.value = text;
            }
        });
    };
    const hideToast = () => {
        if (timerId){
            clearTimeout(timerId);
            setTimerId(null);
        }
        setShowToast(false);
    }
    const openButtonClickHandler = () => {
        const inputElement = linkRef.current;
        if (inputElement) {
            const url = inputElement.value as string;
            if (url.length > 0) {
                const regex = /.*\/view\/([a-zA-Z0-9-]+)/gm;
                const match = regex.exec(url);
                if (match != null) {
                    setShowToast(false);
                    navigate(`/view/${match[1]}`);
                } else {
                    if (timerId) {
                        clearTimeout(timerId);
                        setTimerId(null);
                    }
                    setShowToast(true);
                    const id = setTimeout(hideToast, 2000);
                    setTimerId(id);
                }
            }
        }
    };
    return (<div
        className="flex-grow-1 d-flex flex-column m-4 justify-content-center align-items-center link-input-text">
        <div className="d-flex align-self-stretch flex-column align-items-center mt-2">
            <div className="my-2">{t('enter_link')}</div>
            <div className="d-flex w-50 flex-row justify-content-between align-items-center my-2">
                <Form.Control type="text" placeholder={t("link_placeholder")!} ref={linkRef}
                              className="flex-grow mr-2"/>
                <div
                    className={'welcome-button ml-2'}
                    onClick={pasteButtonClickHandler}>
                    <IconPasteSvg/><span>{t("paste")}</span></div>
            </div>
        </div>
        <Button  className="my-2"  onClick={openButtonClickHandler} text={t('open')}/>
        <Alert show={showToast} variant={"danger"} className="toast-container-welcome" onClose={() => hideToast()}>
            <span className="d-flex justify-content-center align-items-center toast-text-welcome">{t("link_not_recognized")}</span>
        </Alert>
    </div>);
}