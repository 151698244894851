const MAX_ECG_LEADS_COUNT = 8;
const LOW_PASS_BUFFER_LENGTH = 16;

const LOW_PASS_PARAM = 8;
const A0 = 0.99833;
const A1 = 1 - A0;

export class EcgFilter{
    private readonly historyBuffer : Array<Array<number>>;
    private readonly lowPassVector = new Array<number>();
    private readonly averageVector = new Array<number>();
    private historyIndex : number;

    private readonly filterInput = new Array<number>(MAX_ECG_LEADS_COUNT);
    private readonly filterOutput = new Array<number>(MAX_ECG_LEADS_COUNT);

    constructor() {
        this.historyBuffer = new Array<Array<number>>();
        for (let i = 0; i < MAX_ECG_LEADS_COUNT; i++) {
            this.historyBuffer.push(new Array<number>())
            for (let j = 0; j < LOW_PASS_BUFFER_LENGTH; j++) {
                this.historyBuffer[i].push(0);
            }
        }
        this.historyIndex = 0;
        this.lowPassVector = new Array<number>();
        this.averageVector = new Array<number>();
        for (let i = 0; i < MAX_ECG_LEADS_COUNT; i++) {
            this.lowPassVector.push(0);
            this.averageVector.push(0);
        }
    }

    public filter(data : Array<number | null>, lpf: boolean, hpf: boolean) : Array<number | null>{
        for (let j = 0; j < data.length; j++) {
            let value = data[j];
            this.filterInput[j] = value ?? 0;
        }
        const delayedIndex = (this.historyIndex + LOW_PASS_BUFFER_LENGTH - LOW_PASS_PARAM) & (LOW_PASS_BUFFER_LENGTH - 1);
        for (let ch = 0; ch < data.length; ch++ ) {
            this.historyBuffer[ch][this.historyIndex] = this.filterInput[ch];
            this.lowPassVector[ch] = this.lowPassVector[ch] + this.filterInput[ch] - this.historyBuffer[ch][delayedIndex];
            this.averageVector[ch] = (A0 * this.averageVector[ch] + A1 * this.filterInput[ch]);
        }
        this.historyIndex = (this.historyIndex + 1) & (LOW_PASS_BUFFER_LENGTH - 1);
        for (let ch = 0; ch < data.length; ch++) {
            if (lpf) {
                this.filterOutput[ch] = this.lowPassVector[ch] / LOW_PASS_PARAM;
            } else {
                this.filterOutput[ch] = this.filterInput[ch];
            }

            if (hpf) {
                this.filterOutput[ch] = this.filterOutput[ch] - Math.round(this.averageVector[ch]);
            }
        }
        const result = new Array<number | null>();
        for (let i = 0; i < data.length; i++) {
            result[i] = data[i] !== null ? this.filterOutput[i] : null;
        }
        return result;
    }
}
