interface EcgDrawBufferPoint {
    x: number;
    y: number;
}

export class EcgDrawDataBuffer {

    private readonly buffer: Array<Array<Array<EcgDrawBufferPoint> | null>>;
    private readonly size: number;
    private readonly numberOfLeads: number;

    private lastRequestedIndex = -1;

    constructor(numberOfLeads: number, size: number) {
        this.numberOfLeads = numberOfLeads;
        this.size = size;
        this.buffer = new Array<Array<Array<EcgDrawBufferPoint> | null>>();
        for (let i = 0; i < numberOfLeads; i++) {
            const item = new Array<Array<EcgDrawBufferPoint> | null>();
            for (let j = 0; j < size; j++) {
                item.push(null);
            }
            this.buffer.push(item);
        }
    }

    public addVector(x: number, vector: Array<number | null>){
        const idx = Math.floor(x);
        if (idx !== this.lastRequestedIndex){
            for (let i = 0; i < this.numberOfLeads; i++) {
                this.buffer[i][idx] = null;
            }
            this.lastRequestedIndex = idx;
        }
        for (let i = 0; i < this.numberOfLeads; i++) {
            const item = this.buffer[i][idx];
            const y = vector[i];
            if (y !== null){
                if (item === null){
                    this.buffer[i][idx] = [{x: x, y: y} as EcgDrawBufferPoint];
                } else {
                    const lastY = item[item.length - 1].y;
                    if (Math.sign(lastY) !== Math.sign(y)){
                        item.push({x: x, y: y});
                    } else {
                        if (Math.abs(y) > Math.abs(lastY)){
                            item[item.length - 1].y = y;
                        }
                    }
                }
            }
        }
    }

    public buildPoints(lead: number, currentPosition : number, gap: number){
        const dataPoints = new Array<Array<EcgDrawBufferPoint>>();
        const nullPoints = new Array<Array<EcgDrawBufferPoint>>();
        let dataLine : Array<EcgDrawBufferPoint> | null = null;
        let nullLine : Array<EcgDrawBufferPoint> | null = null;
        let count = this.size - gap;
        let position = Math.floor(currentPosition);
        while (count > 0){
            const item = this.buffer[lead][position];
            if (item !== null){
                if (dataLine === null){
                    if (nullLine !== null){
                        nullLine.push({x: position, y: 0});
                        nullPoints.push(nullLine);
                        nullLine = null;
                    }
                    dataLine = new Array<EcgDrawBufferPoint>();
                }
                dataLine.push(...item);
            } else {
                if (nullLine === null){
                    nullLine = new Array<EcgDrawBufferPoint>();
                    if (dataLine !== null){
                        dataPoints.push(dataLine);
                        nullLine.push({x: dataLine[dataLine.length - 1].x, y: 0});
                        dataLine = null;
                    } else {
                        nullLine.push({x : position, y: 0});
                    }
                }
            }
            position--;
            if (position < 0){
                if (nullLine !== null){
                    nullLine.push({x: 0, y: 0});
                    nullPoints.push(nullLine);
                    nullLine = null;
                }
                if (dataLine !== null){
                    dataPoints.push(dataLine);
                    dataLine = null;
                }
                position = this.size - 1;
            }
            count--;
        }
        if (nullLine !== null){
            nullLine.push({x: position, y: 0});
            nullPoints.push(nullLine);
            nullLine = null;
        }
        if (dataLine !== null){
            dataPoints.push(dataLine);
            dataLine = null;
        }
        return [dataPoints, nullPoints];
    }

    public getPoint(lead: number, currentPosition : number){
        let position = Math.floor(currentPosition);
        const points = this.buffer[lead][position];
        let y = 0;
        if (points !== null && points.length > 0){
            y = points[points.length - 1].y;
        }
        return [position, y];
    }

}