const EASI_TRANSFORM = [
    [0.701, 0.026, -0.174],
    [-0.763, -0.002, 1.098],
    [-1.464, -0.028, 1.272],
    [0.031, -0.012, -0.462],
    [1.082, 0.027, -0.723],
    [-1.114, -0.015, 1.185],
    [0.080, 0.641, -0.391],
    [1.021, 1.229, -1.050],
    [0.987, 0.947, -0.539],
    [0.841, 0.525, 0.004],
    [0.630, 0.179, 0.278],
    [0.213, -0.043, 0.431]
];

export function getNumberOfLeads(isEasi: boolean, adcChannels: number) {
    switch (adcChannels) {
        case 2:
            return 6;
        case 3:
            return isEasi ? 12 : 7;
        case 8:
            return 12;
        default:
            throw new Error("Wrong number of channels");
    }
}

export function getLeadName(idx: number) {
    switch (idx) {
        case 0:
            return "I";
        case 1:
            return "II";
        case 2:
            return "III";
        case 3:
            return "aVR";
        case 4:
            return "aVL";
        case 5:
            return "aVF";
        case 6:
            return "V1";
        case 7:
            return "V2";
        case 8:
            return "V3";
        case 9:
            return "V4";
        case 10:
            return "V5";
        case 11:
            return "V6";
        default:
            return "";
    }
}

export function calculateLeads(isEasi: boolean, adcChannels: number, channelData: Array<number | null>): Array<number | null> {
    if (!isEasi || adcChannels === 2) {
        return calculateWilsonLeads(channelData);
    } else {
        return calculateEasiLeads(channelData);
    }
}

function calculateWilsonLeads(channelData: Array<number | null>): Array<number | null> {
    const out = new Array<number | null>();
    let canCalculateLeads = false;
    if (channelData.length >= 2) {
        canCalculateLeads = channelData[0] !== null && channelData[1] !== null
        out.push(channelData[0]);
        out.push(channelData[1]);
        // @ts-ignore
        out.push(canCalculateLeads ? Math.round(channelData[1] - channelData[0]) : null);
    }
    if (channelData.length >= 3) {
        // @ts-ignore
        out.push(canCalculateLeads ? Math.round(-(channelData[0] + channelData[1]) / 2) : null);
        // @ts-ignore
        out.push(canCalculateLeads ? Math.round(channelData[0] - channelData[1] / 2) : null);
        // @ts-ignore
        out.push(canCalculateLeads ? Math.round(channelData[1] - channelData[0] / 2) : null);
        out.push(channelData[2]);
    }
    if (channelData.length === 8) {
        out.push(channelData[3]);
        out.push(channelData[4]);
        out.push(channelData[5]);
        out.push(channelData[6]);
        out.push(channelData[7]);
    }
    return out;
}

function calculateEasiLeads(channelData: Array<number | null>): Array<number | null> {
    const out = new Array<number | null>();
    let canCalculateLeads = channelData[0] !== null && channelData[1] !== null && channelData[2] !== null;
    for (let lead = 0; lead < 12; lead++) {
        out.push(canCalculateLeads ? Math.round(EASI_TRANSFORM[lead][0] * channelData[0]! + EASI_TRANSFORM[lead][1] * channelData[1]! + EASI_TRANSFORM[lead][2] * channelData[2]!) : null);
    }
    return out;
}