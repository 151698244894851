import * as React from "react";

function SvgZoomOutVerticalIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.818 10.307v6.783l1.204-1.203a.66.66 0 01.93 0 .66.66 0 010 .93l-2.326 2.325a.66.66 0 01-.93 0l-2.325-2.325a.66.66 0 010-.93.66.66 0 01.93 0l1.204 1.203v-6.783a.656.656 0 111.314 0z"
        fill="currentColor"
      />
      <path
        d="M4.696 10.662a.657.657 0 01-.192-.466V3.413L3.301 4.616a.66.66 0 01-.93 0 .66.66 0 010-.93l2.325-2.325a.66.66 0 01.93 0l2.325 2.325a.66.66 0 010 .93.66.66 0 01-.93 0L5.818 3.413v6.784a.66.66 0 01-1.122.465z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgZoomOutVerticalIcon;
