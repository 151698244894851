import React from 'react';
import './App.css';
import {Container} from "react-bootstrap";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import {Outlet} from "react-router-dom";

function App() {
    return (
        <Container fluid>
            <Header/>
            <Outlet/>
            <Footer/>
        </Container>
    );
}

export default App;
