import * as React from "react";

function SvgZoomInHorizontalIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#zoominh_svg__clip0)" fill="currentColor">
        <path d="M21.963 12.05h-6.784l1.204 1.203a.66.66 0 010 .93.66.66 0 01-.93 0l-2.326-2.325a.66.66 0 010-.93l2.326-2.325a.66.66 0 01.93 0 .66.66 0 010 .93l-1.204 1.203h6.784a.656.656 0 110 1.314zM1.037 10.928a.657.657 0 01.465-.192h6.783L7.082 9.533a.66.66 0 010-.93.66.66 0 01.93 0l2.325 2.325a.66.66 0 010 .93l-2.325 2.325a.66.66 0 01-.93 0 .66.66 0 010-.93l1.203-1.203H1.502a.66.66 0 01-.465-1.122z" />
      </g>
      <defs>
        <clipPath id="zoominh_svg__clip0">
          <path
            fill="#fff"
            transform="scale(1 -1) rotate(45 33.09 2.885)"
            d="M0 0h15.784v15.784H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgZoomInHorizontalIcon;
