import {NavLink} from "react-router-dom";
import * as AppRoutes from "../../AppRoutes";
import HeaderLogo from "./HeaderLogo";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import React from "react";

interface HeaderFullProps{
    layoutChangeListener?: (isEnoughSpace: boolean) => void;
}

export  const HeaderFull = ({ layoutChangeListener} : HeaderFullProps) => {
    const onLayoutChanged = (width : number) => {
        if (layoutChangeListener){
            layoutChangeListener(width < 198);
        }
    };
    return (
        <div className="header">
            <NavLink to={AppRoutes.HOME_ROUTE}><HeaderLogo sizeChangeListener={layoutChangeListener ? onLayoutChanged : undefined} /></NavLink>
            <div className="flex-grow-1"/>
            <div className="header-options">
                <div className="header-options-dropdown">
                    <LanguageDropdown />
                </div>
            </div>
        </div>
    );
}