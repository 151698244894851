import React from "react";

function SvgSmallLogo(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 33 25" {...props}>
            <rect
                id="logo_svg__backgroundrect"
                width="100%"
                height="100%"
                x={0}
                y={0}
                fill="none"
                stroke="none"
            />
            <defs>
                <linearGradient
                    id="logo_svg__linear-gradient"
                    x1={12.17}
                    y1={13.16}
                    x2={3.66}
                    y2={1}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#1b75bc" />
                    <stop offset={0.12} stopColor="#2080c4" />
                    <stop offset={0.35} stopColor="#2690cf" />
                    <stop offset={0.62} stopColor="#2a99d5" />
                    <stop offset={1} stopColor="#2b9cd7" />
                </linearGradient>
                <linearGradient
                    id="logo_svg__linear-gradient-2"
                    x1={2.55}
                    y1={12.58}
                    x2={33.08}
                    y2={12.58}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#00afb6" />
                    <stop offset={1} stopColor="#41c1c3" />
                </linearGradient>
                <style />
            </defs>
            <g className="logo_svg__currentLayer">
                <path
                    d="M17.793 7.21l-5.18-5.09a7.42 7.42 0 10.1 10.5z"
                    id="logo_svg__svg_1"
                    fill="url(#logo_svg__linear-gradient)"
                />
                <path
                    d="M21.423 2l-5 5.08-2.37 2.46-.91.93c-2.94 2.8-5.35 3.53-7.15 3.53a7.38 7.38 0 01-3.38-1.17l-.1.1 2.48 2.43 1.7 1.64.71.69 1.3 1.31.42.41.7.69 1.72 1.69 1.3 1.28 1.15 1.12a3.42 3.42 0 004.82 0l2.38-2.42 2.6-2.65 7.37-7.51a6.82 6.82 0 00-.17-9.67 6.82 6.82 0 00-9.57.06z"
                    id="logo_svg__svg_2"
                    fill="url(#logo_svg__linear-gradient-2)"
                />
            </g>
        </svg>
    );
}
export default SvgSmallLogo;

