import {NavLink} from "react-router-dom";
import React, {useState} from "react";
import * as AppRoutes from "../../AppRoutes";
import HeaderLogo from "./HeaderLogo";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import {useTranslation} from "react-i18next";
import SvgMenuIcon from "../../Assets/Svg/MenuIcon";


export  const HeaderShort = () => {
    const {t} = useTranslation();
    const [isExpanded, setExpanded] = useState(false);
    const handleToggleClick = () => {
        setExpanded(!isExpanded);
    }
    return (
        <div className="header">
            <NavLink className="drawer-logo" to={AppRoutes.HOME_ROUTE}><HeaderLogo /></NavLink>
            <div className="drawer-toggle" onClick={handleToggleClick}><SvgMenuIcon /></div>
            <div className={`drawer ${isExpanded ? '' : 'hidden'}`}>
                <div className="drawer-options-dropdown">
                    <div className="title disable-select">{t("language")}:</div>
                    <LanguageDropdown />
                </div>
            </div>
        </div>
    );
}