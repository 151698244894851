import {EcgEventData} from "./EcgEventData";

export class EcgEventDataBuffer {

    private readonly startVector: number;
    private readonly events: Array<EcgEventData>;

    constructor(startVector: number) {
        this.startVector = startVector;
        this.events = new Array<EcgEventData>();
    }

    public addEvents(newEvents: Array<EcgEventData>) {
        this.events.push(...newEvents);
    }

    public consumeEvents(startIndex : number, lastIndex : number) : EcgEventData | null {
        let eventData : EcgEventData | null  = null;
        while (this.events.length > 0) {
            let event = this.events[0];
            const idx = event.index - this.startVector;
            if (idx <= lastIndex) {
                if (idx >= startIndex) {
                    eventData = event;
                }
                this.events.shift();
            } else {
                break;
            }
        }
        return eventData;
    }

}