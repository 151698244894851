import {EcgLeadsState} from "./EcgLeadsState";

const SHORT_NULL_VALUE = 0x7FFF;
const HEADER_SIZE = 20;

export interface MqttEcgDataBlock {

    sessionId : number;
    sensorConfig: number;
    adcChannels: number;
    samplingRate: number;
    adcScale: number;
    startVector: number;
    leadsState : EcgLeadsState;
    vectors : Array<number | null>;
}

export function parseMqttEcgDataBlock(rawData : Buffer) : MqttEcgDataBlock | null{
    if (rawData.length < HEADER_SIZE){
        return null;
    }
    let offset = 2;
    const sessionId = rawData.readInt16BE(offset);
    offset += 2;
    const sensorConfig = rawData.readInt16BE(offset);
    offset += 2;
    const adcChannels = rawData.readInt16BE(offset);
    offset += 2;
    const samplingRate = rawData.readInt16BE(offset);
    offset += 2;
    const adcScale = rawData.readInt16BE(offset);
    offset += 2;
    const startVector = rawData.readInt32BE(offset);
    offset += 4;
    const rawLeadsState = rawData.readInt32BE(offset);
    const n = (rawLeadsState & 3) !== 0 ? (rawLeadsState & 3) === 3 : null;
    const r = ((rawLeadsState >> 2) & 3) !== 0 ? ((rawLeadsState >> 2) & 3) === 3 : null;
    const l = ((rawLeadsState >> 4) & 3) !== 0 ? ((rawLeadsState >> 4) & 3) === 3 : null;
    const f = ((rawLeadsState >> 6) & 3) !== 0 ? ((rawLeadsState >> 6) & 3) === 3 : null;
    const c1 = ((rawLeadsState >> 8) & 3) !== 0 ? ((rawLeadsState >> 8) & 3) === 3 : null;
    const c2 = ((rawLeadsState >> 10) & 3) !== 0 ? ((rawLeadsState >> 10) & 3) === 3 : null;
    const c3 = ((rawLeadsState >> 12) & 3) !== 0 ? ((rawLeadsState >> 12) & 3) === 3 : null;
    const c4 = ((rawLeadsState >> 14) & 3) !== 0 ? ((rawLeadsState >> 14) & 3) === 3 : null;
    const c5 = ((rawLeadsState >> 16) & 3) !== 0 ? ((rawLeadsState >> 16) & 3) === 3 : null;
    const c6 = ((rawLeadsState >> 18) & 3) !== 0 ? ((rawLeadsState >> 18) & 3) === 3 : null;
    const leadsState : EcgLeadsState = {
        n: n,
        r : r,
        l : l,
        f : f,
        c1 : c1,
        c2 : c2,
        c3 : c3,
        c4 : c4,
        c5 : c5,
        c6 : c6
    };
    offset += 4;
    const count = Math.floor((rawData.length - HEADER_SIZE) / 2);
    const vectors = new Array<number | null>();
    for (let i = 0; i < count; i++) {
        const s = rawData.readInt16BE(offset);
        offset += 2;
        vectors.push(s === SHORT_NULL_VALUE ? null : s);
    }
    return {
        sessionId : sessionId,
        sensorConfig: sensorConfig,
        adcChannels: adcChannels,
        samplingRate: samplingRate,
        adcScale: adcScale,
        startVector: startVector,
        leadsState : leadsState,
        vectors : vectors
    }
}