import {StateParameter} from "./StateParameter";

export class StateParametersBuffer{

    private stateParameters: Array<StateParameter> | null;

    constructor() {
        this.stateParameters = null;
    }

    public setStateParameters(stateParameters: Array<StateParameter>){
        this.stateParameters = stateParameters;
    }

    public consumeStateParameters() : Array<StateParameter> | null{
        let result = this.stateParameters;
        if (this.stateParameters !== null){
            this.stateParameters = null
        }
        return result;
    }

}