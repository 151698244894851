import {StateParameter} from "./StateParameter";

const HEADER_SIZE = 4;

export interface MqttParameterBlock {
    sessionId: number;
    parameters: Array<StateParameter>;
}

export function parseMqttParameterDataBlock(rawData: Buffer): MqttParameterBlock | null {
    if (rawData.length < HEADER_SIZE) {
        return null;
    }
    let offset = 2;
    const sessionId = rawData.readInt16BE(offset);
    offset += 2;
    const count = Math.floor((rawData.length - HEADER_SIZE) / 4);
    const parameters = new Array<StateParameter>();
    for (let i = 0; i < count; i++) {
        const id = rawData.readInt16BE(offset);
        offset += 2;
        const value = rawData.readInt16BE(offset);
        offset += 2;
        parameters.push({id : id, value: value});
    }
    return {sessionId: sessionId, parameters: parameters};
}