import './EcgMonitor.css';
import {Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {connect} from "mqtt";
import SvgLpfIcon from "../../Assets/Svg/LpfIcon";
import SvgHpfIcon from "../../Assets/Svg/HpfIcon";
import SvgZoomInVerticalIcon from "../../Assets/Svg/ZoomInVerticalIcon";
import SvgZoomOutHorizontalIcon from "../../Assets/Svg/ZoomOutHorizontalIcon";
import SvgZoomInHorizontalIcon from "../../Assets/Svg/ZoomInHorizontalIcon";
import SvgZoomOutVerticalIcon from "../../Assets/Svg/ZoomOutVerticalIcon";
import {useTranslation} from "react-i18next";
import {
    ECG_MV_SCALE_INDEX_DEFAULT_VALUE,
    ECG_MV_SCALE_SETTINGS_KEY,
    ECG_TIME_SCALE_INDEX_DEFAULT_VALUE,
    ECG_TIME_SCALE_SETTINGS_KEY,
    usePersistentState
} from "../../persistance/PersistanceHelper";
import {EcgMonitor} from "./EcgMonitor";
import {EcgRawIndexedDataBuffer} from "./EcgRawIndexedDataBuffer";
import {parseMqttEcgDataBlock} from "./MqttEcgDataBlock";
import Loader from "../Loader/Loader";
import {useParams} from "react-router-dom";
import {parseMqttEotDataBlock} from "./MqttEotDataBlock";
import {parseMqttEcgEventDataBlock} from "./MqttEcgEventDataBlock";
import {EcgEventDataBuffer} from "./EcgEventDataBuffer";
import {parseMqttParameterDataBlock} from "./MqttParameterBlock";
import {StateParametersBuffer} from "./StateParametersBuffer";

const MIN_BUFFER_DURATION = 3;
export const PX_PER_MM = 3;
export const MV_SCALES = [2.5, 5, 10, 20, 40];
export const TIME_SCALES = [12.5, 25, 50, 100];

const ECG_BLOCK_PACKAGE_TYPE = 1;
const EOT_BLOCK_PACKAGE_TYPE = 3;
const ECG_EVENT_BLOCK_PACKAGE_TYPE = 7;
const PARAMETERS_PACKAGE_TYPE = 15;

interface EcgBlockMetadata {
    sessionId: number,
    sensorConfig: number;
    adcChannels: number;
    samplingRate: number;
    adcScale: number;
    startIndex: number;
}

interface State {
    ecgMetadata: EcgBlockMetadata | null;
    ecgBuffer: EcgRawIndexedDataBuffer | null;
    ecgEventBuffer: EcgEventDataBuffer | null;
    stateParametersBuffer: StateParametersBuffer;
    streamingStartTime: number;
}

export function EcgView() {
    const {t} = useTranslation();
    const {userId} = useParams();
    const container = useRef<HTMLDivElement>(null);
    const state = useMemo(() => {
        return {
            ecgMetadata: null,
            ecgBuffer: null,
            ecgEventBuffer: null,
            stateParametersBuffer : new StateParametersBuffer(),
            streamingStartTime: 0
        } as State
    }, []);
    const [connectionState, setConnectionState] = useState(t('state_connecting'));
    const [width, setWidth] = useState(0);
    const [defaultMvScaleIndex] = usePersistentState(ECG_MV_SCALE_SETTINGS_KEY, ECG_MV_SCALE_INDEX_DEFAULT_VALUE);
    const [defaultTimeScaleIndex] = usePersistentState(ECG_TIME_SCALE_SETTINGS_KEY, ECG_TIME_SCALE_INDEX_DEFAULT_VALUE);
    const [mvScaleIndex, setMvScaleIndex] = useState(defaultMvScaleIndex);
    const [timeScaleIndex, setTimeScaleIndex] = useState(defaultTimeScaleIndex);
    const mvScale = useMemo(() => MV_SCALES[mvScaleIndex], [mvScaleIndex]); // eslint-disable-line
    const timeScale = useMemo(() => TIME_SCALES[timeScaleIndex], [timeScaleIndex]); // eslint-disable-line
    const [lpf, setLpf] = useState(true);
    const [hpf, setHpf] = useState(true);
    useLayoutEffect(() => {
        setWidth(container.current?.clientWidth ?? 0);
    }, [container.current?.clientWidth]); // eslint-disable-line
    const resizeEventListener = () => setWidth(container.current?.clientWidth ?? 0);
    useEffect(() => {
        window.addEventListener("resize", resizeEventListener);
        return (() => {
            window.removeEventListener("resize", resizeEventListener)
        });
    });
    const isMaxVerticalZoom = mvScaleIndex === MV_SCALES.length - 1;
    const isMinVerticalZoom = mvScaleIndex === 0;
    const isMaxHorizontalZoom = timeScaleIndex === TIME_SCALES.length - 1;
    const isMinHorizontalZoom = timeScaleIndex === 0;
    useEffect(() => {
        const client = connect('wss://8e5db211e9094ff78182426751d87196.s2.eu.hivemq.cloud:8884/mqtt', {
            username: 'binora_rt',
            password: '9vPooLu18T&9',
            clean: true
        });
        client.on('connect', () => {
            setConnectionState(t('state_awaiting_data'));
        });
        client.on('error', (error) => {
            console.log(error);
        });
        client.on('message', (topic, message) => {
            if (message.length > 2) {
                const messageId = message.readInt16BE(0);
                if (messageId === ECG_BLOCK_PACKAGE_TYPE) {
                    const block = parseMqttEcgDataBlock(message);
                    if (block !== null) {
                        if (state.ecgBuffer === null || state.ecgMetadata === null || state.ecgMetadata.sessionId !== block.sessionId) {
                            const metadata = {
                                sessionId: block.sessionId,
                                sensorConfig: block.sensorConfig,
                                adcChannels: block.adcChannels,
                                samplingRate: block.samplingRate,
                                adcScale: block.adcScale,
                                startIndex: block.startVector
                            }
                            const buffer = new EcgRawIndexedDataBuffer(metadata.adcChannels, metadata.samplingRate, metadata.startIndex);
                            buffer.addData(block.vectors, block.startVector);
                            state.ecgBuffer = buffer;
                            state.ecgMetadata = metadata;
                            state.ecgEventBuffer = new EcgEventDataBuffer(block.startVector);
                            state.streamingStartTime = 0;
                            setConnectionState(t('buffering'));
                        } else {
                            state.ecgBuffer.addData(block.vectors, block.startVector);
                        }
                        if (state.ecgBuffer && state.streamingStartTime === 0 && state.ecgBuffer.getDuration() > MIN_BUFFER_DURATION) {
                            state.streamingStartTime = Date.now();
                            setConnectionState("");
                        }
                    }
                }
                if (messageId === EOT_BLOCK_PACKAGE_TYPE) {
                    const block = parseMqttEotDataBlock(message);
                    if (block !== null) {
                        if (state.ecgBuffer !== null && state.ecgMetadata !== null && state.ecgMetadata.sessionId === block.sessionId) {
                            state.ecgBuffer.close();
                        }
                    }
                }
                if (messageId === ECG_EVENT_BLOCK_PACKAGE_TYPE) {
                    const block = parseMqttEcgEventDataBlock(message);
                    if (block !== null) {
                        if (state.ecgEventBuffer !== null && state.ecgMetadata !== null && state.ecgMetadata.sessionId === block.sessionId) {
                            state.ecgEventBuffer.addEvents(block.events);
                        }
                    }
                }
                if (messageId === PARAMETERS_PACKAGE_TYPE) {
                    const block = parseMqttParameterDataBlock(message);
                    if (block !== null) {
                        state.stateParametersBuffer.setStateParameters(block.parameters);
                    }
                }
            }
        });
        if (userId) {
            client.subscribe(`${userId}/#`);
        }
        return () => {
            client.end(true);
        }
    }, [userId]);
    const isReady = width > 0 && state.ecgMetadata && state.ecgBuffer && state.ecgEventBuffer && state.streamingStartTime > 0 && connectionState === "";
    const streamingFinishedHandler = useCallback(() => {
        state.ecgBuffer = null;
        state.ecgMetadata = null;
        state.ecgEventBuffer = null;
        state.streamingStartTime = 0;
        setConnectionState(t('state_awaiting_data'));
    }, [state.ecgBuffer, state.ecgMetadata]);
    return (
        <div className={"ecg-viewer mt-4"} ref={container}>
            {!isReady &&
                <Loader text={connectionState}/>
            }
            {isReady &&
                <Fragment>
                    <EcgMonitor sensorConfig={state.ecgMetadata!.sensorConfig}
                                adcChannels={state.ecgMetadata!.adcChannels}
                                samplingRate={state.ecgMetadata!.samplingRate} adcScale={state.ecgMetadata!.adcScale}
                                width={width} mvScale={mvScale} timeScale={timeScale} lpf={lpf}
                                hpf={hpf} dataBuffer={state.ecgBuffer!} eventBuffer={state.ecgEventBuffer!} stateParametersBuffer={state.stateParametersBuffer}
                                streamingStartTime={state.streamingStartTime} streamingFinishedCallback={streamingFinishedHandler}/>
                    <div className="d-flex justify-content-center my-4">
                        <div className="d-flex justify-content-center mr-2">
                            <div className={`ecg-viewer-button ${lpf ? "active" : ""}`} onClick={() => setLpf(!lpf)}>
                                <SvgLpfIcon/><span>{t("hpf_tooltip")}</span></div>
                            <div className={`ecg-viewer-button ${hpf ? "active" : ""}`} onClick={() => setHpf(!hpf)}>
                                <SvgHpfIcon/><span>{t("lpf_tooltip")}</span></div>
                            <div className={`ecg-viewer-button ${isMaxVerticalZoom ? "active" : ""}`}
                                 onClick={() => setMvScaleIndex(Math.min(MV_SCALES.length - 1, mvScaleIndex + 1))}>
                                <SvgZoomOutVerticalIcon/><span>{t("mv_zoom_out_tooltip")}</span></div>
                            <div className={`ecg-viewer-button ${isMinVerticalZoom ? "active" : ""}`}
                                 onClick={() => setMvScaleIndex(Math.max(0, mvScaleIndex - 1))}>
                                <SvgZoomInVerticalIcon/><span>{t("mv_zoom_in_tooltip")}</span></div>
                            <div className={`ecg-viewer-button ${isMaxHorizontalZoom ? "active" : ""}`}
                                 onClick={() => setTimeScaleIndex(Math.min(TIME_SCALES.length - 1, timeScaleIndex + 1))}>
                                <SvgZoomOutHorizontalIcon/><span>{t("time_zoom_out_tooltip")}</span></div>
                            <div className={`ecg-viewer-button ${isMinHorizontalZoom ? "active" : ""}`}
                                 onClick={() => setTimeScaleIndex(Math.max(0, timeScaleIndex - 1))}>
                                <SvgZoomInHorizontalIcon/><span>{t("time_zoom_in_tooltip")}</span>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </div>
    );
}