import {EcgEventData} from "./EcgEventData";

const HEADER_SIZE = 4;

export interface MqttEcgEventDataBlock {

    sessionId: number;

    events: Array<EcgEventData>;
}

export function parseMqttEcgEventDataBlock(rawData: Buffer): MqttEcgEventDataBlock | null {
    if (rawData.length < HEADER_SIZE) {
        return null;
    }
    let offset = 2;
    const sessionId = rawData.readInt16BE(offset);
    offset += 2;
    const count = Math.floor((rawData.length - HEADER_SIZE) / 16);
    const events = new Array<EcgEventData>();
    for (let i = 0; i < count; i++) {
        const index = rawData.readInt32BE(offset);
        offset += 4;
        const hr = rawData.readInt32BE(offset);
        offset += 4;
        const qrsStatus = rawData.readInt32BE(offset);
        offset += 4;
        const ani = rawData.readInt32BE(offset);
        offset += 4;
        events.push({index: index, hr: hr, qrsStatus: qrsStatus, ani: ani});
    }
    return {sessionId: sessionId, events: events};
}