import './Loader.css';
import SvgSmallLogo from "../../Assets/Svg/SmallLogo";

interface Props {
    text?: string | null;
}

function Loader(props: Props) {
    return (
        <div className="loader-logo">
            <SvgSmallLogo/>
            {props.text &&
                <div className="loader-text">{props.text}</div>
            }
        </div>
    );
}

export default Loader;