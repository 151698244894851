import React, {useEffect, useMemo, useRef, useState} from "react";
import {Circle, Group, Layer, Line, Rect, Stage, Text} from "react-konva";
import {PX_PER_MM} from "./EcgView";
import {useTranslation} from "react-i18next";
import {calculateLeads, getLeadName, getNumberOfLeads} from "./EcgHelper";
import {getStyledColor} from "./DrawHelper";
import {EcgRawIndexedDataBuffer} from "./EcgRawIndexedDataBuffer";
import {EcgFilter} from "./EcgFilter";
import {EcgEventDataBuffer} from "./EcgEventDataBuffer";
import Konva from "konva";
import {EcgDrawDataBuffer} from "./EcgDrawDataBuffer";
import {StateParametersBuffer} from "./StateParametersBuffer";
import {
    PARAMETER_EL_CO_2_ID,
    PARAMETER_KD_ID,
    PARAMETER_LAK_ID,
    PARAMETER_PT_ID,
    PARAMETER_RR_ID,
    PARAMETER_TV_ID
} from "./StateParameter";

const MM_PER_LEAD = 20;

const MAJOR_LINE_WIDTH_PX = 1.2;
const MINOR_LINE_WIDTH_PX = 1;
const MILLI_VOLT_LINE_WIDTH_PX = 2;
const MILLI_VOLT_TEXT_SIZE_MM = 2.5;
const PARAMETER_TEXT_SIZE_MM = 10;
const SCALE_DATA_TEXT_SIZE_MM = 3.5;
const LEGEND_OPACITY = 0.75;
const DATA_LINE_WIDTH = 1.5;

interface State {
    lastRequestedIndex: number;
    ecgFilter: EcgFilter;
    hrText: string;
    aniText: string;
    rrText: string;
    tvText: string;
    ptText: string;
    kdText: string;
    elCo2Text: string;
    lakText: string;
    ecgInfoColor: string;
}

interface Props {
    width: number;
    sensorConfig: number;
    adcChannels: number;
    samplingRate: number;
    adcScale: number;
    mvScale: number;
    timeScale: number;
    lpf: boolean;
    hpf: boolean;
    streamingStartTime: number;
    dataBuffer: EcgRawIndexedDataBuffer;
    eventBuffer: EcgEventDataBuffer,
    stateParametersBuffer: StateParametersBuffer;

    streamingFinishedCallback: () => void;
}


export const EcgMonitor: React.FC<Props> = ({
                                                width,
                                                sensorConfig,
                                                adcChannels,
                                                samplingRate,
                                                adcScale,
                                                mvScale,
                                                timeScale,
                                                lpf,
                                                hpf,
                                                streamingStartTime,
                                                dataBuffer,
                                                eventBuffer,
                                                stateParametersBuffer,
                                                streamingFinishedCallback
                                            }: Props) => {
    const {t} = useTranslation();
    const state = useMemo(() => {
        return {
            lastRequestedIndex: -1,
            ecgFilter: new EcgFilter(),
            hrText: t("hr_format", {hrData: "-"}),
            aniText: t("ani_format", {aniData: "-"}),
            rrText: "",
            tvText: "",
            ptText: "",
            kdText: "",
            elCo2Text: "",
            lakText: "",
            ecgInfoColor: getStyledColor("--ecg_qrs_noisy")
        } as State;
    }, []);
    const ecgInfoBlink = useRef<Konva.Layer>(null);
    const rrBlink = useRef<Konva.Group>(null);
    const tvBlink = useRef<Konva.Group>(null);
    const ptBlink = useRef<Konva.Group>(null);
    const kdBlink = useRef<Konva.Group>(null);
    const elCo2Blink = useRef<Konva.Group>(null);
    const lakBlink = useRef<Konva.Group>(null);
    const [isFinished, setFinished] = useState(false);
    const [timestamp, setTimestamp] = useState(0);
    const isEasi = (sensorConfig ?? 0) === 1;
    const numberOfLeads = useMemo(() => getNumberOfLeads(isEasi, adcChannels), [adcChannels]); // eslint-disable-line
    const height = useMemo(() => (numberOfLeads ?? 0) * MM_PER_LEAD * PX_PER_MM, [numberOfLeads]); // eslint-disable-line
    const mvScaleFactor = useMemo(() => -1 * mvScale * PX_PER_MM / adcScale, [mvScale, adcScale]);
    const timeScaleFactor = useMemo(() => timeScale * PX_PER_MM / samplingRate, [timeScale, samplingRate]);
    const buffer = useMemo(() => {
        const buffer = new EcgDrawDataBuffer(numberOfLeads, width);
        state.lastRequestedIndex = -1;
        state.ecgFilter = new EcgFilter();
        return buffer;
    }, [numberOfLeads, width, mvScaleFactor, timeScaleFactor, lpf, hpf]);
    useEffect(() => {
        if (isFinished) {
            streamingFinishedCallback();
        } else {
            setTimeout(() => {
                setTimestamp(Date.now());
            }, 5);
        }
    });
    const elapsedTime = useMemo(() => Date.now() - streamingStartTime, [timestamp, streamingStartTime]);
    const requestedIndex = Math.floor(elapsedTime / 1000 * samplingRate);
    const lastIndex = useMemo(() => Math.min(dataBuffer.getDataSize() - 1, requestedIndex), [dataBuffer, requestedIndex]);
    const firstIndex = useMemo(() => Math.max(state.lastRequestedIndex + 1, lastIndex - dataBuffer.getCapacity()), [state.lastRequestedIndex, lastIndex, dataBuffer]);
    for (let i = firstIndex; i <= requestedIndex; i++) {
        let vector;
        if (i <= lastIndex) {
            vector = dataBuffer.getVector(i);
        } else {
            if (!isFinished && dataBuffer.isClosed()) {
                setFinished(true);
            }
            vector = new Array<number | null>(adcChannels).fill(null);
        }
        const filteredData = state.ecgFilter.filter(vector, lpf, hpf);
        const leadsData = calculateLeads(isEasi, adcChannels, filteredData);
        const scaledLeadsData = leadsData.map(sample => sample != null ? sample * mvScaleFactor : null);
        buffer.addVector((i * timeScaleFactor) % width, scaledLeadsData);
        state.lastRequestedIndex = lastIndex;
    }
    const ecgEventData = eventBuffer.consumeEvents(firstIndex, requestedIndex);
    if (ecgEventData !== null) {
        const hrData = ecgEventData.hr >= 0 ? `${ecgEventData.hr}` : '-';
        const aniData = ecgEventData.ani >= 0 ? `${ecgEventData.ani}%` : '-';
        const hrText = t("hr_format", {hrData: hrData});
        const aniText = t("ani_format", {aniData: aniData});
        let color;
        if (ecgEventData.qrsStatus === 1) {
            color = getStyledColor("--ecg_qrs_normal");
        } else if (ecgEventData.qrsStatus === 4) {
            color = getStyledColor("--ecg_qrs_noisy");
        } else {
            color = getStyledColor("--ecg_qrs_bad");
        }
        state.hrText = hrText;
        state.aniText = aniText;
        state.ecgInfoColor = color;
        ecgInfoBlink.current?.to({
            opacity: 0,
            duration: 0.15,
            onFinish: () => {
                ecgInfoBlink.current?.to({
                    opacity: 1,
                    duration: 0.15
                });
            }
        });
    }
    const blinkParameter = (elRef : React.RefObject<Konva.Group>) => {
        elRef.current?.to({
            opacity: 0,
            duration: 0.15,
            onFinish: () => {
                elRef.current?.to({
                    opacity: 2.4,
                    duration: 0.15,
                    onFinish: () => {
                        elRef.current?.to({
                            opacity: 1,
                            duration: 30
                        });
                    }
                });
            }
        });
    }
    const stateParameters = stateParametersBuffer.consumeStateParameters();
    if (stateParameters !== null) {
        for (let i = 0; i < stateParameters.length; i++) {
            const parameter = stateParameters[i];
            switch (parameter.id) {
                case PARAMETER_RR_ID:
                    const newRrText = parameter.value !== -1 ? t("rr_format", {rrData: parameter.value}) : "";
                    if (newRrText !== state.rrText) {
                        state.rrText = newRrText;
                        blinkParameter(rrBlink);
                    }
                    break;
                case PARAMETER_TV_ID:
                    const newTvText = parameter.value !== -1 ? t("tv_format", {tvData: parameter.value}) : "";
                    if (newTvText !== state.tvText) {
                        state.tvText = newTvText;
                        blinkParameter(tvBlink);
                    }
                    break;
                case PARAMETER_PT_ID:
                    const newPtText = parameter.value !== -1 ? t("pt_format", {ptData: parameter.value}) : "";
                    if (newPtText !== state.ptText) {
                        state.ptText = newPtText;
                        blinkParameter(ptBlink);
                    }
                    break;
                case PARAMETER_KD_ID:
                    const newKdText = parameter.value !== -1 ? t("kd_format", {kdData: parameter.value}) : "";
                    if (newKdText !== state.kdText) {
                        state.kdText = newKdText;
                        blinkParameter(kdBlink);
                    }
                    break;
                case PARAMETER_EL_CO_2_ID:
                    const newElCo2Text = parameter.value !== -1 ? t("el_co_2_format", {elCo2Data: parameter.value}) : "";
                    if (newElCo2Text !== state.elCo2Text) {
                        state.elCo2Text = newElCo2Text;
                        blinkParameter(elCo2Blink);
                    }
                    break;
                case PARAMETER_LAK_ID:
                    const newLakText = parameter.value !== -1 ? t("lak_format", {lakData: parameter.value}) : "";
                    if (newLakText !== state.lakText) {
                        state.lakText = newLakText;
                        blinkParameter(lakBlink);
                    }
                    break;
            }
        }
    }
    const dataPointers = new Array<Array<number>>();
    const dataPoints = new Array<Array<Array<number>>>();
    const nullPoints = new Array<Array<Array<number>>>();
    const screenPosition = Math.floor(elapsedTime / 1000 * timeScale * PX_PER_MM) % width;
    for (let i = 0; i < (numberOfLeads ?? 0); i++) {
        const offsetY = (i + 0.5) * MM_PER_LEAD * PX_PER_MM;
        const [dataLines, nullLines] = buffer.buildPoints(i, screenPosition, 5 * PX_PER_MM);
        dataPointers.push(buffer.getPoint(i, screenPosition));
        dataPointers[i][1] += offsetY;
        dataPoints.push(dataLines.map(line => line.flatMap(points => [points.x, points.y + offsetY])));
        nullPoints.push(nullLines.map(line => line.flatMap(points => [points.x, points.y + offsetY])));
    }
    let base = null;
    let border = null;
    let majorGridLines = null;
    let minorGridLines = null;
    let scaleInfo = null;
    let milliVolts = null;
    let leadsDataLines = null;
    let leadsNullLines = null;
    let leadsDataPointers = null;
    let hrData = null;
    let aniData = null;
    let rrData = null;
    let tvData = null;
    let ptData = null;
    let kdData = null;
    let elCo2Data = null;
    let lakData = null;
    if (numberOfLeads && width > 0 && height > 0) {
        const backgroundColor = getStyledColor("--ecg-background");
        const gridLineColor = getStyledColor("--ecg-grid-line");
        const contentColors = [getStyledColor("--ecg-content-1"), getStyledColor("--ecg-content-2"), getStyledColor("--ecg-content-3")];
        const nullColor = getStyledColor("--ecg-content-null");
        const scaleDataColor = getStyledColor("--primary-text");
        const parameterColor = getStyledColor("--primary-text");
        base = <Rect width={width} height={height} x={0} y={0} strokeEnabled={false} fill={backgroundColor}
                     fillEnabled={true}/>
        border =
            <Rect width={width} height={height} x={0} y={0} strokeWidth={MAJOR_LINE_WIDTH_PX} stroke={gridLineColor}/>
        majorGridLines = [];
        minorGridLines = [];
        const gridStep = 5 * PX_PER_MM;
        let iX = 1;
        for (let x = gridStep; x < width; x += gridStep) {
            if (iX % 2 === 0) {
                majorGridLines.push(<Line key={`h${iX}`} points={[x, 0, x, height]} strokeWidth={MAJOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            } else {
                minorGridLines.push(<Line key={`h${iX}`} points={[x, 0, x, height]} strokeWidth={MINOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            }
            iX++;
        }
        let iY = 1;
        for (let y = gridStep; y < height; y += gridStep) {
            if (iY % 2 === 0) {
                majorGridLines.push(<Line key={`v${iY}`} points={[0, y, width, y]} strokeWidth={MAJOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            } else {
                minorGridLines.push(<Line key={`v${iY}`} points={[0, y, width, y]} strokeWidth={MINOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            }
            iY++;
        }
        const parameterSize = PARAMETER_TEXT_SIZE_MM * PX_PER_MM;
        let paramY = 5 * PX_PER_MM;
        const paramStep = height / 8;
        hrData = <Text key='p-hr' x={0} y={paramY} fill={state.ecgInfoColor} opacity={0.75}
                       fontStyle={"bold"} fontSize={parameterSize} width={width - 5 * PX_PER_MM} align={"right"}
                       text={state.hrText ?? ""}/>;
        paramY += paramStep;
        aniData = <Text key='p-ani' x={0} y={paramY} fill={state.ecgInfoColor} opacity={0.75}
                        fontStyle={"bold"} fontSize={parameterSize} width={width - 5 * PX_PER_MM} align={"right"}
                        text={state.aniText ?? ""}/>;
        paramY += paramStep;
        rrData = <Text key='p-rr' x={0} y={paramY} fill={parameterColor} opacity={0.4}
                       fontStyle={"bold"} fontSize={parameterSize} width={width - 5 * PX_PER_MM} align={"right"}
                       text={state.rrText ?? ""}/>;
        paramY += paramStep;
        tvData = <Text key='p-tv' x={0} y={paramY} fill={parameterColor} opacity={0.4}
                       fontStyle={"bold"} fontSize={parameterSize} width={width - 5 * PX_PER_MM} align={"right"}
                       text={state.tvText ?? ""}/>;
        paramY += paramStep;
        ptData = <Text key='p-pt' x={0} y={paramY} fill={parameterColor} opacity={0.4}
                       fontStyle={"bold"} fontSize={parameterSize} width={width - 5 * PX_PER_MM} align={"right"}
                       text={state.ptText ?? ""}/>;
        paramY += paramStep;
        kdData = <Text key='p-kd' x={0} y={paramY} fill={parameterColor} opacity={0.4}
                       fontStyle={"bold"} fontSize={parameterSize} width={width - 5 * PX_PER_MM} align={"right"}
                       text={state.kdText ?? ""}/>;
        paramY += paramStep;
        elCo2Data = <Text key='p-elC02' x={0} y={paramY} fill={parameterColor} opacity={0.4}
                          fontStyle={"bold"} fontSize={parameterSize} width={width - 5 * PX_PER_MM} align={"right"}
                          text={state.elCo2Text ?? ""}/>;
        paramY += paramStep;
        lakData = <Text key='p-lac' x={0} y={paramY} fill={parameterColor} opacity={0.4}
                        fontStyle={"bold"} fontSize={parameterSize} width={width - 5 * PX_PER_MM} align={"right"}
                        text={state.lakText ?? ""}/>;
        let scaleData = t("ecg_scale_data_format", {mvScale: mvScale.toFixed(1), timeScale: timeScale.toFixed(1)});
        if (isEasi) {
            scaleData = `EASI     ${scaleData}`;
        }
        const scaleDataTextSize = SCALE_DATA_TEXT_SIZE_MM * PX_PER_MM;
        scaleInfo = <Text x={0} y={height - 0.75 * PX_PER_MM - scaleDataTextSize} fill={scaleDataColor} opacity={0.5}
                          fontStyle={"bold"} fontSize={scaleDataTextSize} width={width - 5 * PX_PER_MM} align={"right"}
                          text={scaleData}/>;
        const mvX1 = 0;
        const mvY1 = 0;
        const mvX2 = 1.5 * PX_PER_MM;
        const mvY2 = -mvScale * PX_PER_MM;
        const mvX3 = 3.5 * PX_PER_MM;
        const mvX4 = 5 * PX_PER_MM;
        milliVolts = [];
        for (let lead = 0; lead < numberOfLeads; lead++) {
            const name = getLeadName(lead);
            const offsetY = (lead + 0.5) * MM_PER_LEAD * PX_PER_MM;
            const color = contentColors[lead % contentColors.length];
            milliVolts.push(
                <Group key={`mvg-${lead}`}>
                    <Line key={`mv-${lead}`}
                          points={[mvX1, mvY1 + offsetY, mvX2, mvY1 + offsetY, mvX2, mvY2 + offsetY, mvX3, mvY2 + offsetY, mvX3, mvY1 + offsetY, mvX4, mvY1 + offsetY]}
                          strokeWidth={MILLI_VOLT_LINE_WIDTH_PX} opacity={LEGEND_OPACITY}
                          stroke={color}/>
                    <Text key={`mvt-${lead}`} x={0} width={5 * PX_PER_MM} y={offsetY + 2 * PX_PER_MM} text={name}
                          fill={color} opacity={LEGEND_OPACITY} fontStyle={"bold"}
                          fontSize={MILLI_VOLT_TEXT_SIZE_MM * PX_PER_MM} align={"center"}/>
                </Group>
            );
        }
        leadsDataLines = dataPoints.flatMap((lines, lead) => lines.map((points, i) => <Line key={`ld-${lead}-${i}`}
                                                                                            lineJoin={"round"}
                                                                                            points={points}
                                                                                            strokeWidth={DATA_LINE_WIDTH}
                                                                                            stroke={contentColors[lead % contentColors.length]}/>));
        leadsNullLines = nullPoints.flatMap((lines, lead) => lines.map((points, i) => <Line key={`ln-${lead}-${i}`}
                                                                                            lineJoin={"round"}
                                                                                            points={points}
                                                                                            strokeWidth={DATA_LINE_WIDTH}
                                                                                            stroke={nullColor}/>));
        leadsDataPointers = dataPointers.map((point, lead) => <Circle key={`cd-${lead}`} x={point[0]} y={point[1]}
                                                                      radius={0.75 * DATA_LINE_WIDTH}
                                                                      stroke={contentColors[lead % contentColors.length]}
                                                                      fill={contentColors[lead % contentColors.length]}/>);
    }
    return (
        <Stage width={width} height={height}>
            <Layer>
                {base ?? base}
                {minorGridLines && minorGridLines}
                {majorGridLines && majorGridLines}
                {border && border}
                {milliVolts && milliVolts}
                {leadsDataLines && leadsDataLines}
                {leadsNullLines && leadsNullLines}
                {leadsDataPointers && leadsDataPointers}
                {scaleInfo && scaleInfo}
            </Layer>
            <Layer>
                <Group ref={ecgInfoBlink}>
                    {hrData && hrData}
                    {aniData && aniData}
                </Group>
                <Group ref={rrBlink}>
                    {rrData && rrData}
                </Group>
                <Group ref={tvBlink}>
                    {tvData && tvData}
                </Group>
                <Group ref={ptBlink}>
                    {ptData && ptData}
                </Group>
                <Group ref={kdBlink}>
                    {kdData && kdData}
                </Group>
                <Group ref={elCo2Blink}>
                    {elCo2Data && elCo2Data}
                </Group>
                <Group ref={lakBlink}>
                    {lakData && lakData}
                </Group>
            </Layer>
        </Stage>
    );
}