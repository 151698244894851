const HEADER_SIZE = 4;

export interface MqttEotDataBlock {

    sessionId : number;
}

export function parseMqttEotDataBlock(rawData : Buffer) : MqttEotDataBlock | null{
    if (rawData.length < HEADER_SIZE){
        return null;
    }
    let offset = 2;
    const sessionId = rawData.readInt16BE(offset);
    return {
        sessionId : sessionId,
    }
}