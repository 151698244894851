const BUFFER_SIZE_SECONDS = 90;

export class EcgRawIndexedDataBuffer {

    private readonly data: Array<Array<number | null>>;
    private readonly capacity: number;
    private readonly numberOfChannels: number;
    private readonly samplingRate: number;
    private readonly startIndex: number;
    private size: number;

    private closed : boolean;

    constructor(numberOfChannels: number, samplingRate: number, startIndex: number) {
        this.capacity = BUFFER_SIZE_SECONDS * samplingRate;
        this.numberOfChannels = numberOfChannels;
        this.data = new Array<Array<number | null>>();
        for (let i = 0; i < numberOfChannels; i++) {
            const subArray = new Array<number | null>();
            for (let k = 0; k < this.capacity; k++) {
                subArray.push(null)
            }
            this.data.push(subArray);
        }
        this.size = 0;
        this.samplingRate = samplingRate;
        this.startIndex = startIndex;
        this.closed = false;
    }

    public getCapacity(): number {
        return this.capacity;
    }

    public getNumberOfChannels(): number {
        return this.numberOfChannels;
    }

    public getDataSize(): number {
        return this.size;
    }

    public getDuration() : number{
        return this.size / this.samplingRate;
    }

    public getData(channelNumber: number, position: number): number | null {
        return this.data[channelNumber][position % this.capacity];
    }

    public isClosed() : boolean{
        return this.closed;
    }

    public getVector(position: number): Array<number | null> {
        const idx = position % this.capacity;
        const result = new Array<number | null>();
        for (let i = 0; i < this.numberOfChannels; i++) {
            result.push(this.data[i][idx]);
        }
        return result;
    }

    public addData(newData: Array<number | null>, position: number) {
        if (newData.length % this.data.length === 0) {
            if (position - this.startIndex > (Math.floor(this.size / this.capacity) - 1) * this.capacity) {
                let i = 0;
                while (i < newData.length) {
                    let idx = (position - this.startIndex) % this.capacity;
                    if (idx < 0) {
                        idx = this.capacity + idx;
                    }
                    for (let j = 0; j < this.data.length; j++) {
                        this.data[j][idx] = newData[i++];
                    }
                    position++;
                    this.size = Math.max(position - this.startIndex, this.size);
                }
            }
        } else {
            throw(new Error("Wrong data size"));
        }
    }


    public close() {
        this.closed = true;
    }

}