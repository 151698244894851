import React from 'react';
import SvgSmallLogo from '../../Assets/Svg/SmallLogo';
import { HOME_ROUTE } from '../../AppRoutes';
import './NotFound.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

function NotFound() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const handleHomePageClick = () => {
        navigate(HOME_ROUTE);
    }
    return (
        <div className="not-found-container">
            <div className="not-found">
                <SvgSmallLogo />
                <div className="not-found-title mt-4">{t("page_not_found_title")}</div>
                <div className="not-found-text mt-2">{t("page_not_found_description")}</div>
                <div className="not-found-link mt-4" onClick={handleHomePageClick}>{t("return_to_home_page")}</div>
            </div>
        </div>
    );
}

export default NotFound;