import React, {Fragment, useState} from 'react';
import './Header.css';
import {useTranslation} from "react-i18next";
import {HeaderFull} from "./HeaderFull";
import {HeaderShort} from "./HeaderShort";

function Header() {
    const {t} = useTranslation();
    const [isShort, setShort] = useState(null as boolean | null);
    const layoutChangeListener = (isEnoughSpace: boolean) => {
        if (!isShort || isShort !== isEnoughSpace) {
            setShort(isEnoughSpace);
        }
    };
    return (
        <Fragment>
            <div style={{position: "absolute", left: 0, top: -1000, width: "100%", padding: "0 15px", opacity: 0}}>
                <HeaderFull layoutChangeListener={layoutChangeListener}/>
            </div>
            {isShort !== null &&
            isShort ? <HeaderShort /> :<HeaderFull />}
        </Fragment>
    );
}

export default Header;